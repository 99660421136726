import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

import styled from 'styled-components';
import Thestudio from '../components/Home/Thestudio';
import studiodata from '../Json/studiodata.json';

const SectionStudioGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function Studiopage() {
  return (
    <Layout>
    <Seo title="Stuxio Network studio" />
    <div className="Hero">
      <div className="HeroGroup">
      <h2>Studio</h2>
        <SectionStudioGroup>
          {studiodata.studios.map((thestudio) => (
            <Thestudio
              title={thestudio.title}
              title2={thestudio.title2}
              title3={thestudio.title3}
              title4={thestudio.title4}
              image={thestudio.image}
              image2={thestudio.image2}
              image3={thestudio.image3}
              image4={thestudio.image4}
              image5={thestudio.image5}
              text={thestudio.text}
              text2={thestudio.text2}
              text3={thestudio.text3}
              text4={thestudio.text4}
              url={thestudio.url}
            />
          ))}
        </SectionStudioGroup>
      </div>
    </div>
    </Layout>
  );
}

